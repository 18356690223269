




























































import { Component, PropSync, Vue } from 'vue-property-decorator';
import StrategyModerateWealthDialogViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/strategy-moderate-wealth-dialog-view-model';

@Component({
  name: 'StrategyModerateWealthDialog',
  components: {
    StrategyModerateWealthExitPoll: () => import('@/vue-app/components/strategy-moderate-wealth/StrategyModerateWealthExitPoll.vue'),
  },
})
export default class StrategyModerateWealthDialog extends Vue {
  @PropSync('isOpen', { type: Boolean })
  synced_is_open!: boolean;

  @PropSync('goalName', { type: String })
  synced_goal_name!: string;

  @PropSync('investorGoalId', { type: String })
  synced_investor_goal_id!: string;

  strategy_moderate_wealth_model = Vue.observable(new StrategyModerateWealthDialogViewModel(this));

  closeModal() {
    this.synced_is_open = false;
  }

  showHiringFlowDialog() {
    this.strategy_moderate_wealth_model.showHiringFlowDialog();
    this.closeModal();
  }

  showExitPollDialog() {
    this.strategy_moderate_wealth_model.showExitPollDialog();
    this.closeModal();
  }
}
