import TYPES from '@/types';

// Domain
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';

export default class StrategyModerateWealthDialogViewModel {
  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-moderate-wealth-dialog';

  goal_name = '';

  private readonly view: any;

  public constructor(view: any) {
    this.view = view;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  showExitPollDialog = () => {
    this.view.$emit('showExitPollDialog');
  }

  showHiringFlowDialog = () => {
    this.view.$emit('showHiringFlowDialog');
  }
}
